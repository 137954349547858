<template>
  <div class="topic-details">
    <!-- 题 -->

    <p style=" font-size: 30px; text-align: center;">{{VariantName}}</p>
    <el-card class="active" ref="barparent">
      <el-steps :active="active" finish-status="success">
        <el-step v-for="(item, i) in taskAmount" :key="i"></el-step>
      </el-steps>

      <el-card class="box-card-left">
        <el-scrollbar style="height: 100%">
          <div>
            <span v-if="false">题目描述</span>
            <ul>
              <li
                v-for="(item, i) in descriptions"
                :key="i"
                :class="item.descriptionType==6?'class3':''"
              >
                {{ descriptionType[item.descriptionType] }}
                <b v-html="item.description" class="des"></b>
              </li>
            </ul>
          </div>
        </el-scrollbar>
      </el-card>
      <el-card class="box-card-right">
        <el-scrollbar style="height: 100%">
          <div v-for="(item, i) in questions" :key="i">
            <span style="border-radius:none;padding-left:5px">
              {{ item.sn }}
              <i class="qtype">[ {{ questionsType[item.type] }} ]</i>
            </span>
            <p v-html="item.stemContent" class="stemContent"></p>
            <div class="choices">
              <span
                :class="
                  completionStatus
                    ? v.userChoice == 1
                      ? 'text-option class3'
                      : 'text-option'
                    : v.isAnswer == 1
                    ? 'text-option class3'
                    : 'text-option class4'
                "
                style="display:inline-block"
                v-for="(v, index) in item.list"
                :key="index"
              >
                {{ v.sn }}
                <i v-html="v.choiceContent" class="choices"></i>
                <i v-if="v.cause" class="wrong">
                  错误原因:
                  <div class="reason" v-html="v.cause"></div>
                </i>
              </span>
              <!-- <span
                :class="
                  item.userAnswerResult == 0 ? 'answer green' : 'answer red'
                "
                v-if="completionStatus"
              >
                {{ userAnswerResultType[item.userAnswerResult] }}
                <i
                  class="answerChoices"
                >正确答案:{{ item.answer.toString().replace(/,/g, "、") }}</i>
              </span>-->
            </div>
          </div>
        </el-scrollbar>
      </el-card>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [],
      params: {},
      active: 0,
      taskAmount: null,
      count: 0,
      time: "00:00",
      descriptions: [],
      questions: [],
      selected: false,
      selecteObj: {},
      answer: {},
      dialogVisible: false,
      knowledge: [],
      totalTime: 0,
      descriptionType: this.parameter.descriptionType(),
      questionsType: {
        2: "单选题",
        3: "多选题",
      },
      userAnswerResultType: {
        0: "您的答案正确!",
        1: "您的答案错误!",
      },
      showButton: true,
      showNext: false,
      subjectId: 0,
      completionStatus: false,
      isComplete: 1,
      VariantName: '',
    };
  },
  mounted () {
    this.list = JSON.parse(this.$route.query.list);
    this.VariantName = this.$route.query.name;
    this.descriptions = this.list[0].children;
    this.questions = this.list[1].children;
    //处理正确答案
    for (let i = 0; i < this.questions.length; i++) {

      this.questions[i].list = this.questions[i].list.sort((a, b) => {
        return (a.sn + '') > (b.sn + '') ? 1 : -1;
      })
      this.questions[i].answer = this.questions[i].list.map((a, i) => {
        let str = null
        a.isAnswer == 1 ? str = a.sn : str = null
        return str;
      })
      this.questions[i].answer = this.questions[i].answer.filter((item) => {
        return item !== null
      })
    }
  },
  methods: {},
};
</script>

<style lang='less' scoped>
@import '../../../style/subject/subject.less';
@import '../../../style/mouse.less';
.class3 {
  background: #d8e8ff !important;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.class4 {
  background: green;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des/deep/img {
  vertical-align: text-top;
}

.stemContent/deep/img {
  vertical-align: middle;
}
.choices/deep/p {
  display: inline-block;
  & img {
    height: 100%;
    vertical-align: middle;
  }
}
.activity-button {
  width: 100% !important;
}
.wrong {
  border-top: 1px solid rgb(184, 184, 184);
  display: block;
  color: red;
  margin-top: 10px;
  div {
    display: inline-block;
  }
}
</style>